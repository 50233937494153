<template>
  <div class="col-12 p-0">
    <div class="container-lg" >
      <PageTitle :title="$route.name" />
      <div class="row mx-0 justify-content-center">
        <div class="col-12 p-0">
          <div class="row m-0 justify-content-center">
            <div class="col-12 text-center p-0 mt-4 mb-3 page_heading">
              {{user.firstName}} {{user.lastName}}
            </div>
            <div class="col-12 text-center mb-3 p-0 gold_heading">
              DONOR ID: {{user.donorID}}
            </div>
            <div class="col-12 text-center p-0 top_text">
              {{user.email}}
            </div>
          </div>
          <div class="row m-0 justify-content-center d-md-none">
            <div class="col-7 mt-3 text-center">
              <Button color="green" width="100" btnText="EDIT PROFILE" @buttonClicked="editProfile" />
            </div>
          </div>
          <div class="row m-0 justify-content-center d-md-none">
            <div class="col-7 my-3 text-center">
              <Button color="red" width="100" btnText="Log Out" icon="arrow" @buttonClicked="signout" class="px-2">
                <IconLogOut size="size16" color="white" />
              </Button>
            </div>
          </div>
          <!-- <div class="row m-0 justify-content-center d-none">
            <div class="col-auto my-3 text-center">
              <Button color="red" btnText="TEST DONATE ON BEHALF" @buttonClicked="donateonbehalf" />
            </div>
          </div> -->
        </div>
        <div class="col-auto text-center px-0 mt-md-5">
          <div class="profile_img">
            <Image :imageName="user.imageUrl" width="188" v-if="user.imageUrl" />
            <IconUser size="sizefull" v-else />
          </div>
        </div>
      </div>
      <div class="row m-0 justify-content-center d-none d-md-flex">
        <div class="col-auto my-5 text-center">
          <Button color="green" btnText="EDIT PROFILE" @buttonClicked="editProfile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    IconUser: defineAsyncComponent(() => import('@/components/icons/IconUser.vue')),
    IconLogOut: defineAsyncComponent(() => import('@/components/icons/IconLogOut.vue'))
  },
  name: 'ProfileInfo',
  methods: {
    ...mapActions(['logout']),
    async signout () {
      await this.$router.push('/')
      this.logout()
    },
    editProfile () {
      this.$router.push('/profileedit')
    },
    donateonbehalf () {
      this.$router.push('/checkout/2')
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  }
}
</script>

<style scoped>
.profile_img {
  height: 103px;
  width: 103px;
  border-radius: 50%;
  position: relative;
  border: 4px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.gold_heading {
  color: var( --gold-color );
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 5px 5px 10px rgb(0 0 0 / 67%);
}
.top_text {
  color: #FFFFFF;
  padding: 12px 0px 0px 0px;
  text-shadow: 5px 5px 10px rgb(0 0 0 / 67%);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 992px) {
  .gold_heading {
    font-size: 1.4rem;
  }
  .top_text {
    font-size: 1.2rem;
  }
  .profile_img {
    height: 188px;
    width: 188px;
  }
}
</style>
